.adxad-chips-input {
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  &__label {
    display: flex;
    flex-direction: column;

    &-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }
  }

  &:not(.disabled):hover &__label {
    &-content {
      color: #2076ff;
    }
  }

  &__input {
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    display: flex;

    &-input {
      flex-grow: 1;
      box-sizing: border-box;
      padding: 11px 16px;
      transition: all 0.15s ease-in-out;
      width: 100%;
      height: 40px;
      outline: none;
      font-family: $font-family-base;
      font-size: 14px;
      color: $black;
      font-weight: 400;
      line-height: 1;
      border: none;

      @include inputPlaceholder() {
        color: $inputPlaceholderColor;
        font-size: 13px;
        font-family: $font-family-base;
        font-weight: 400;
      }

      &:disabled {
        border-color: $inputDisabledBorderColor;
        color: $inputDisabledColor;
        background: $inputDisabledBg;

        @include inputPlaceholder() {
          color: $inputDisabledPlaceholderColor;
        }
      }
    }

    .btn-enter {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: solid 1px $borderColor;
      border-radius: 6px;
      font-size: 18px;
      transition: all 0.15s ease-in-out;
      color: $iconsGray;

      &:hover {
        background: $backgroundHoverColor;
        color: $mainBlue;
      }
    }
  }
}
