.adxad-link-btn {
  font-family: $font-family-base;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  border: none;
  border-radius: 8px;
  display: inline-block;
  white-space: nowrap;
  background: transparent;
  color: #4f5e76;
  padding: 0 24px;
  height: 40px;
  cursor: pointer;

  &:hover {
    color: #4d7ac0;
  }

  &:active {
    color: #4f5e76;
  }

  &:focus {
    outline: none;
  }

  &_icon {
    padding-left: 40px;
    position: relative;

    .material-icons,
    .material-icons-outlined {
      position: absolute;
      left: 11px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 24px;
    }
  }

  &:disabled {
    border: none;
    color: #bdbdbd;
    cursor: default;

    .material-icons,
    .material-icons-outlined {
      color: #bdbdbd;
    }
  }
}
