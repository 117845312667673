.adxad-slide-toggle {
  cursor: pointer;
  display: inline-block;

  &__toggle {
    width: 32px;
    height: 18px;
    border-radius: 32px;
    background: $stBg;
    position: relative;
    transition: background-color 0.15s ease-out;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 6px 0 inset;

    &:after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      left: 3px;
      top: 3px;
      transition: left 0.15s ease-out;
    }

    &.active {
      background: $stBgActive;
    }

    &.active:after {
      left: 17px;
    }
  }

  &__content {
    margin-left: 8px;
  }

  &.disabled {
    cursor: default;
  }
  &.disabled &__toggle {
    background: $stBgDisabled;

    &:after {
      background: $stFlagBgDisabled;
    }
  }

  &.disabled &__wrapper {
    pointer-events: none;
  }
}
