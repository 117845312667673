@import "../mixins/placeholder";

.adxad-input {
  display: block;
  position: relative;

  &__input {
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 11px 11px 11px 16px;
    color: $black;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: 400;
    line-height: 1;
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 40px;
    outline: none;

    @include inputPlaceholder() {
      color: $inputPlaceholderColor;
      font-size: 13px;
      font-family: $font-family-base;
      font-weight: 400;
    }

    &:disabled {
      border-color: $inputDisabledBorderColor;
      color: $inputDisabledColor;
      background: $inputDisabledBg;

      @include inputPlaceholder() {
        color: $inputDisabledPlaceholderColor;
      }
    }

    &:read-only {
      border-style: dashed;
      border-color: $inputDisabledBorderColor;
      color: $inputDisabledColor;

      @include inputPlaceholder() {
        color: $inputDisabledPlaceholderColor;
      }
    }
  }

  &.ng-invalid.ng-touched &__input {
    border-color: $errorColor;

    @include inputPlaceholder() {
      color: $errorColor;
    }
  }

  &__label {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }
  }

  &:not(.disabled):hover &__label {
    .label-content {
      color: #2076ff;
    }
  }

  &.disabled,
  &.readonly {
    .label-content {
      color: $inputDisabledLabelColor;
    }

    .adxad-prefix,
    .adxad-suffix {
      color: $inputDisabledPlaceholderColor;
    }
  }

  &.ng-invalid.ng-touched &__label {
    .label-content {
      color: $errorColor;
    }
  }

  //suffix && prefix
  .adxad-suffix,
  .adxad-prefix {
    position: absolute;
    bottom: 8px;
    z-index: 2;
  }

  &.suffix &__input {
    padding-right: 38px;
  }

  &.prefix &__input {
    padding-left: 38px;
  }

  .adxad-suffix {
    right: 12px;
  }

  .adxad-prefix {
    left: 10px;
  }

  // Material design
  &.material {
    .adxad-prefix {
      color: $stBgDisabled;
    }

    .adxad-input__input {
      border: none;
      border-bottom: 1px solid #cfd8dc;
      padding: 0;
      border-radius: 0;
      height: 40px;

      &:focus {
        border-bottom-color: $mainBlue;

        & + .adxad-prefix {
          color: $mainBlue;
        }
      }
    }

    .adxad-input__btns {
      border-left: none;
    }
  }

  &.material.prefix .adxad-input__input {
    padding-left: 32px;
  }

  &.material.suffix .adxad-input__input {
    padding-right: 32px;
  }

  &.material.suffix .adxad-suffix {
    right: 0;
  }

  &.material.prefix .adxad-prefix {
    left: 0;
  }

  &.material.suffix .adxad-suffix {
    right: 0;
  }
}
