.adxad-tabs {
  display: block;
}

.adxad-tabs-nav {
  display: flex;
  border-bottom: 1px solid #e3e6ed;
  gap: 0 20px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 16px;
}

.adxad-tab-label {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 14px;
  color: #a3adc1;
  display: inline-block;
  cursor: pointer;
  padding: 13px 0;
  margin-bottom: -1px;
  position: relative;
  transition: color 0.15s ease-in-out;

  &:not(.disabled):hover {
    color: #4f5e76;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 4px;
    background: linear-gradient(190.01deg, #ff3d6c 0%, #ff5a59 59.37%, #ff8b59 100%);
    transition: width 0.2s ease-in-out;
  }

  &.active {
    color: #4f5e76;

    &:focus {
      outline: none;
    }

    &:after {
      width: 100%;
    }
  }

  &.disabled {
    cursor: default;
    color: #d7dbe5;
    pointer-events: none;
  }
}
