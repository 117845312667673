@import "input";
@import "number-input";
@import "textarea";
@import "select";
@import "checkbox";
@import "datepicker";
@import "label";
@import "slide-toggle";
@import "autocomplete";
@import "options";
@import "chips-input";

.adxad-form-field {
  display: block;

  &__container {
    position: relative;
  }
}

.adxad-error {
  font-size: 11px;
  padding-top: 4px;
  color: $errorColor;
}
