@import "../mixins/placeholder";
@import "vars";

.adxad-datepicker {
  display: block;

  &.disabled {
    pointer-events: none;
  }

  &__container {
    display: block;
  }

  &__field {
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 40px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &.ng-invalid.ng-touched &__field {
    border-color: $errorColor;
  }

  &__label {
    color: $labelColor;
    font-size: 12px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 8px;
    display: block;

    .disabled & {
      color: $labelDisableColor;
    }
  }

  &:not(.disabled):hover &__label {
    color: $hoverColor;
  }

  &.ng-invalid.ng-touched &__label {
    color: $errorColor;
  }

  &__input {
    margin: 0;
    background: #fff;
    font-size: 14px;
    line-height: 1;
    color: $black;
    border-radius: 8px;
    border: 1px solid #e3e6ed;
    padding: 10px 38px 10px 16px;
    width: 100%;
    height: 100%;
    font-family: $font-family-base;

    &:focus {
      outline: none;
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      border-color: #e0e0e0;
      color: #a0a0a0;
      background: #f4f4f4;
    }

    @include inputPlaceholder() {
      color: #a3adc1;
      font-size: 13px;
      font-family: $font-family-base;
      font-weight: 400;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    width: 40px;
    margin: auto;
    color: #b5bdcd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .material-icons {
      font-size: 16px;
    }

    &:hover {
      color: #2076ff;
    }

    &.disabled {
      color: #9e9e9e;
    }
  }

  &.ng-invalid.ng-touched &__icon {
    color: $errorColor;
  }

  &.ng-invalid.ng-touched &__input {
    border-color: $errorColor;

    @include inputPlaceholder() {
      color: $errorColor;
    }
  }

  &__calendar {
    position: absolute;
    width: 340px;
    height: 420px;
    z-index: 10000;
    top: -9999px;
    left: -9999px;
  }

  &__animate-container {
    position: relative;
    background: #fff;
    padding: 24px 24px 16px;
    border-radius: 8px;
    box-shadow: $shadow;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 24px;
  }

  &__selector {
    flex-basis: 0;
    flex-grow: 2;
    max-width: 100%;
    text-align: center;
    cursor: pointer;
    color: $black;
    font-weight: 600;
    position: relative;

    & + & {
      flex-grow: 1;
      text-align: left;
    }

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #607d8b transparent transparent transparent;
      margin-left: 8px;
    }

    &.disabled {
      pointer-events: none;

      &:after {
        display: none;
      }
    }
  }

  &__arrow {
    color: $gray300;
    flex: 0 0 24px;
    max-width: 24px;
    cursor: pointer;
    transition: color $linkAnimation;

    &:hover {
      outline: none;
      color: $linkColor;
    }
  }

  &__day-names {
    display: flex;
    justify-content: space-around;

    margin-left: -24px;
    margin-right: -24px;
    border-bottom: 1px solid #e0e0e0;
    color: #bdbdbd;
    padding: 0 24px 8px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__day-name,
  &__date {
    width: 41px;
    text-align: center;
    margin-bottom: 8px;
  }

  &__date {
    position: relative;
    cursor: pointer;
    color: $black;

    &-container {
      width: 38px;
      height: 38px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      transition:
        border-color $linkAnimation,
        color $linkAnimation;
      border: 1px solid transparent;
    }

    &.today {
      font-weight: bold;
      font-size: 18px;
    }

    &:hover &-container {
      color: $mainBlue;
      border-color: $mainBlue;
    }

    &.selected &-container {
      color: #fff;
      background: $mainBlue;
    }

    &.disabled {
      color: #bdbdbd;
      pointer-events: none;
      cursor: default;
    }
  }

  &__months-list,
  &__years-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 30px;

    &-item {
      width: 33.33333%;
      text-align: center;
      padding: 6px;
      border-radius: 8px;
      margin-bottom: 12px;
      cursor: pointer;
      font-weight: 500;
      border: 1px solid transparent;
      transition:
        border-color $linkAnimation,
        color $linkAnimation;

      &._current {
        font-weight: bold;
      }

      &:hover {
        border-color: $mainBlue;
        color: $mainBlue;
      }
    }
  }

  &__years-list {
    padding-top: 15px;

    &-item {
      width: 25%;
    }
  }
}

//calendar container
.adxad-calendar-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;

  &__mask {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
