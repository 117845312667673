@import "alerts";
@import "reload-token";
@import "modal";
@import "buttons/index";
@import "blocks/page";
@import "blocks/grid";
@import "blocks/paginator";
@import "fields/fields";
@import "blocks/sidebar-modal";
@import "blocks/tabs";
@import "blocks/tooltip";
@import "blocks/chips";
@import "blocks/loader";
@import "blocks/accordion";
@import "chips";

.link {
  text-decoration: none;
  color: $linkColor;
  cursor: pointer;
  transition: color $linkAnimation;

  &:hover {
    outline: none;
    color: $linkHoverColor;
  }
}

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.w100 {
  width: 100%;
}

.adxad-toolbar {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-width: 100%;

  &.hide {
    display: none;
  }
}

.adxad-grid-header {
  position: relative;
  padding-bottom: 36px;

  &__add-btn {
    position: absolute;
    right: 0;
    z-index: 10;
    transform: translateY(-50%);
  }
}

.adxad-grid {
  border: 1px solid #e3e6ed;
  border-radius: 8px;
  overflow-x: auto;

  .checked {
    background: #f9f9fb;
  }

  &.hide {
    display: none;
  }
}

.adxad-header-title {
  margin-top: 0;
  font-weight: 700;
}

.adxad-subtitle {
  font-weight: 700;
  font-size: 14px;
  color: $black;
  margin-bottom: $colMargin * 2;
}

.adxad-actions {
  align-items: center;
  display: flex;
  gap: 0 8px;
}

.adxad-add-btn {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background: linear-gradient(225deg, #ff3d6c 0%, #ff5a59 59.37%, #ff8b59 100%);
  color: #fff;
  border: none;
  padding: 12px;
  z-index: 2;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

.field-group {
  margin-bottom: $colMargin * 2;
}

.adxad-stepper {
  position: relative;
  display: flex;
  justify-content: space-between;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1px;
    background: #e3e6ed;
    z-index: -1;
  }

  &__item {
    color: #a3adc1;
    font-weight: 500;
    padding: 8px 18px;
    background: #fff;
    border: 1px solid #e3e6ed;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-icon {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: #b5bdcd;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 13px;
      margin-right: 8px;

      &.active {
        background: #2076ff;
      }

      &.invalid {
        background: #fff;
        color: $errorColor;
      }

      &.success {
        background: #fff;
        color: #b5bdcd;
      }
    }

    &:disabled {
      cursor: default;
      background: #dbdee5;
      color: #acafb4;
      border: 1px solid #dbdee5;
    }

    &:focus {
      outline: none;
    }

    &.active {
      color: #4f5e76;
    }

    &:disabled &-icon {
      color: #dbdee5;
    }
  }
}

.hide {
  display: none;
}
