.adxad-toggle-button-group {
  &__content {
    border: $lightBtnBorder;
    padding: 4px;
    border-radius: 8px;
    height: 40px;
    display: inline-flex;
    align-items: center;
  }

  &.disabled {
    .adxad-toggle-button-group__content {
      border-color: #e0e0e0;
      color: #9e9e9e;
      background: #f4f4f4;
    }
  }

  .label {
    color: #8492a6;
    font-size: 12px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 8px;
    display: block;
    width: 100%;
  }
}

.adxad-toggle-button {
  padding: 0 16px;
  border-radius: 6px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  background: #fff;

  & + & {
    margin-left: 4px;
  }

  &.selected {
    background: #e2f5ff;
  }

  &:not(.selected):not(.disabled):hover {
    background: #eceff1;
  }

  &:focus {
    outline: none;
  }

  &.focus:not(.disabled) {
    background: #eceff1;

    &.selected {
      background: #d1efff;
    }
  }

  &.disabled {
    border-color: #e0e0e0;
    color: #9e9e9e;
    background: #f4f4f4;
    cursor: default;
  }
}
