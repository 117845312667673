.mat-mdc-table {
  border: none;
  border-spacing: initial;
  background: none;
  width: 100%;

  .mat-mdc-row {
    &.checked,
    &:hover {
      background: #f9f9fb;
    }
  }

  .mat-mdc-cell {
    vertical-align: center;
    border-bottom-width: 0 !important;
    color: #8492a6;
  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  .mat-mdc-header-cell {
    box-sizing: border-box;
    padding: 6px 4px;
    white-space: nowrap;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  .mat-mdc-header-cell {
    font-size: 12px;
    font-weight: 400;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
}

.copy-value {
  position: relative;

  .material-icons {
    width: 16px;
    height: 16px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 4px;
    transition: color 0.15s ease-out;

    &:hover {
      color: #2076ff;
    }
  }
}
