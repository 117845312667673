.adxad-alerts {
  position: fixed;
  left: 30px;
  right: 30px;
  bottom: 0;
  min-width: 300px;
  max-width: 600px;
  margin: auto;
  z-index: 1000000;
  display: flex;
  flex-direction: column-reverse;

  &__item {
    &:nth-child(3) {
      opacity: 0.8;
    }
    &:nth-child(4) {
      opacity: 0.5;
    }
    &:nth-child(5) {
      opacity: 0;
    }
  }
}

.adxad-alert-block {
  font-family: $font-family-base;
  font-size: 16px;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  height: 0;
  transition: height 0.3s;
  color: $black;

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    padding: 16px 52px 16px 16px;
    width: 100%;
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &_error {
    color: #fff;
    background: $errorColor;
    background: rgba(244, 67, 54, 0.8);
  }

  &_info {
    color: #fff;
    background: rgba(51, 204, 255, 0.8);
  }

  &_success {
    color: #fff;
    background: rgba(76, 175, 80, 0.8);
  }

  &_warning {
    color: #fff;
    background: rgba(255, 153, 0, 0.8);
  }
}

@keyframes alertOut {
  to {
    opacity: 0;
    height: 0;
  }
}
