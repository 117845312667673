@import "vars";

.adxad-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &-spinner {
    width: 80px;
    height: 80px;

    &__spinner {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid;
      border-color: $errorColor transparent $errorColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
