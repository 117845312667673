@import "assets/styles/vars";
@import "assets/styles/table";
@import "assets/styles/grid";
@import "app/ui/styles/ui";
@import "media.mixins";
@import "@angular/cdk/a11y-prebuilt.css";

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  color: $black;
  overflow-x: hidden;
  font-family: $font-family-base;
  font-size: $font-size-base;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media("max-width", "512px") {
    overflow-y: scroll;
  }
}

.intercom-lightweight-app {
  z-index: 10 !important;
}

.grecaptcha-badge {
  display: none;
}

/** Scroll */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #dbdee5;
  border-radius: 24px;

  &:hover {
    background: #b5bdcd;
  }

  &:active {
    background: #667386;
  }
}

/** End Scroll */

.terms {
  line-height: 24px;
  font-size: 13px;
  margin: 24px 0 0;

  .terms__link {
    font-weight: 500;
    color: #2076ff;

    &:hover {
      text-decoration: none;
    }
  }
}

.ad-block-warning {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 20px;
  margin: auto;
  color: white;
  position: fixed;
  z-index: 1001;
  width: 100%;
  bottom: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
}

.terms {
  &__link {
    font-weight: 500;
    color: $mainBlue;

    &:hover {
      text-decoration: none;
    }
  }
}
