.adxad-grid {
  border: 1px solid #e3e6ed;
  border-radius: 8px;
  overflow-x: auto;
}

.dynamic-grid-loader {
  padding: 64px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// row loader
.mat-mdc-row.row-loading {
  pointer-events: none;
  cursor: default;
  background: #eee;
  animation: row-loading 1.2s linear infinite alternate;
}

@keyframes row-loading {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.2;
  }
}
