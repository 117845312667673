.global-scroll-block {
  overflow-y: hidden;
}

.adxad-sidebar-modal {
  position: fixed;
  z-index: 1000;
  overflow: hidden;

  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;

  &__mask {
    z-index: 0;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  &__close {
    position: absolute;
    z-index: 10;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: #b5bdcd;
  }

  &__wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    width: 600px;
    box-shadow: 8px 8px 40px rgba(8, 28, 53, 0.4);
    background: #fff;
  }

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: auto;
  }

  &__header {
    padding: 0 32px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 32px 72px 32px;
  }

  &__bar {
    margin-left: -48px;
    margin-right: -48px;
    padding: 24px 48px;
    background: #f7f8fa;
    color: #2076ff;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__bar-action {
    align-self: flex-end;
    margin-left: auto;
    align-items: center;
    display: flex;
    column-gap: 16px;
  }

  &__launch-btn {
    margin-left: 8px;
    cursor: pointer;
    color: #a3adc1;
    font-size: 18px;

    &:hover {
      color: $mainBlue;
    }
  }

  &__search {
    margin-left: -48px;
    margin-right: -48px;
    padding: 0 48px;
    border-bottom: 1px solid #e3e6ed;
    position: relative;
  }

  &__list {
    margin-left: -48px;
    margin-right: -48px;
    overflow: auto;
  }

  &__actions {
    display: flex;
    //grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
  }

  &__action {
    align-items: center;
    display: flex;
    height: 72px;
    border: none;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    font-family: $font-family-base;
    font-size: 12px;
    justify-content: center;
    margin: 0;
    padding: 0 16px;
    gap: 0 8px;
    flex-grow: 1;

    .material-icons,
    .material-icons-outlined {
      width: 16px;
      height: 16px;
      font-size: 16px;
    }

    &_main-red {
      color: #fff;
      background: $redGradient;

      &:hover {
        background: #ff3d6c;
      }
    }

    &_main-green {
      color: #fff;
      background: rgb(167, 224, 0);
      background: $greenGradient;

      &:hover {
        background: #20a159;
      }
    }

    &_main-blue {
      color: #fff;
      background: $blueGradient;

      &:hover {
        background: #2051ff;
      }
    }

    &_main-orange {
      color: #fff;
      background: $orangeGradient;

      &:hover {
        background: #ffa500;
      }
    }

    &_main-gray {
      background: #eaecf2;
      color: #4f5e76;

      &:hover {
        background: #e2e3e9;
      }
    }

    &:active {
      outline: none;
    }

    &.disabled {
      pointer-events: none;
      color: $lightBtnTextDisabled;
      background: $lightBtnBgDisabled;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    color: #474f5c;
    margin: 16px 0 24px;
    word-break: break-all;
    display: flex;
    align-items: center;
    position: relative;

    .material-icons {
      margin-right: 8px;
    }
  }

  &__section-title {
    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
  }

  &__info-block {
    display: block;
    margin-bottom: 24px;

    &-title {
      align-items: center;
      display: flex;
      color: #8492a6;
      font-size: 12px;
      margin-bottom: 4px;

      .--help {
        cursor: pointer;
        color: $lightBtnIcon;
        font-size: 17px;
        margin-left: 4px;

        &:hover {
          color: $mainBlue;
        }
      }
    }

    &-description {
      color: #474f5c;
      font-weight: 600;
      font-size: 13px;
      word-break: break-all;
    }
  }

  &__search {
    position: relative;

    &-input {
      border: none;
      background: none;
      width: 100%;
      font-size: 16px;
      padding: 16px 0 16px 32px;
      position: relative;
      z-index: 1;
      color: #474f5c;
      font-family: $font-family-base;

      &:focus {
        outline: none;
      }
    }

    &-icon {
      color: #a3adc1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 48px;
      z-index: 0;
      margin: auto;
      width: 24px;
      height: 24px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 21px;
    right: 21px;
    color: $black;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s ease-out;

    &:hover {
      opacity: 1;
    }
  }
}

.adxad-sidebar-modal-section {
  margin-bottom: 16px;
  border-bottom: 1px solid #e3e6ed;

  &_hide-separator {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.adxad-sidebar-modal-preview {
  margin-left: -48px;
  margin-right: -48px;
  padding-top: 100%;
  position: relative;
  background: #f6f7fb;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.adxad-sidebar-modal-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
