.adxad-chip-holder-list,
.adxad-chip-list {
  display: flex;
  flex-wrap: wrap;
}

.adxad-chip-holder,
.adxad-chip {
  display: inline-flex;
  margin: 0 8px 8px 0;
  cursor: pointer;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 32px;
    padding: 6px 12px;
    border: 1px solid $borderColor;
    background: #fff;
    font-weight: 500;
    font-size: 13px;
    transition: all 0.15s ease-out;

    & .material-icons {
      margin-left: 8px;
      font-size: 16px;
    }

    &:hover {
      color: $hoverColor;
      background: #eceff1;
    }

    &.active {
      background: #2b7dff;
      color: #fff;
    }

    &.stroked {
      background: #fff;
      color: #4f5e76;
      border: 1px solid #e3e6ed;
      font-weight: bold;

      &:hover {
        background: #eceff1;
      }

      &.active {
        background: #eaecf2;
        color: #4f5e76;
      }
    }
  }
}
