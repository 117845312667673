.adxad-accordion {
  display: flex;
  flex-direction: column;
  margin: 0 -32px;

  &__item {
    display: flex;
    flex-direction: column;

    &-header {
      align-items: center;
      justify-content: space-between;
      display: flex;
      box-sizing: border-box;
      background: #f7f8fa;
      padding: 16px 32px;
      transition: color 0.2s ease-out;

      &:hover {
        cursor: pointer;
        color: $hoverColor;
        background-color: $gray50;
      }

      .title {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        gap: 0 8px;

        .material-icons {
          font-size: 16px;
        }
      }
    }

    &-body {
      box-sizing: border-box;
      padding: 16px 32px;
    }

    &.--invalid {
      .title {
        .--error {
          animation: heartBeat 1.3s ease-in-out infinite;
          color: $errorColor;
        }
      }
    }
  }

  &.--ml0 {
    margin-left: 0;
  }

  &.--mr0 {
    margin-right: 0;

    .adxad-accordion__item-header {
      padding-right: 16px;
    }
    .adxad-accordion__item-body {
      padding-right: 0;
    }
  }
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}
