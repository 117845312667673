.adxad-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &__mask {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.288);
  }

  &__container {
    border-radius: 4px;
    padding: 24px 32px 32px;

    box-shadow:
      0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);

    box-sizing: border-box;
    overflow: auto;
    outline: 0;

    background: #fff;
    max-width: 80vw;
    max-height: 100%;
    width: 600px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }

  &__title {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 16px;
  }

  &__content {
    display: block;
    max-height: 65vh;
    overflow: auto;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  &__actions {
    display: flex;
    gap: 0 16px;
    padding-top: 24px;

    a,
    button {
      flex-grow: 1;
    }
  }

  &__close-btn {
    position: absolute;
    top: 21px;
    right: 21px;
    color: $black;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s ease-out;

    &:hover {
      opacity: 1;
    }
  }
}

.adxad-modal-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
