.adxad {
  &-header-section {
    box-sizing: border-box;
    display: flex;
    gap: 16px 0;
    padding: 24px 0 16px;
  }

  &-grid-header {
    position: relative;
    padding-bottom: 36px;

    &__add-btn {
      position: absolute;
      right: 0;
      z-index: 10;
      transform: translateY(-50%);
    }
  }
}
