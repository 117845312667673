@import "vars";

.adxad-tooltip {
  --tooltip-color: #{$tooltipColor};

  &.--gray-tooltip {
    --tooltip-color: #{$tooltipGrayColor};
  }

  background: var(--tooltip-color);
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  transition:
    opacity $tooltipAnimationBezier,
    transform $tooltipAnimationBezier;
  transform: translateY(-16px);
  z-index: 100000;
  opacity: 0;
  max-width: 300px;

  &.--gray-tooltip {
    box-shadow: $shadow;
  }

  &.open {
    opacity: 1;
    transform: translateY(0);
  }

  &:not(.open) {
    pointer-events: none;
  }

  a {
    color: $tooltipLinkColor;
    text-decoration: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    margin: auto;
    z-index: 2;

    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--tooltip-color);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    height: 16px;
    width: 100%;
  }

  &-left {
    &:after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid var(--tooltip-color);

      top: 50%;
      transform: translateY(-50%);
      left: 100%;
    }

    &:before {
      top: 0;
      left: 100%;
      width: 16px;
      height: 100%;
    }
  }

  &-right {
    &:after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid var(--tooltip-color);

      top: 50%;
      transform: translateY(-50%);
      left: -16px;
      right: auto;
    }

    &:before {
      top: 0;
      left: -16px;
      width: 16px;
      height: 100%;
    }
  }

  &-bottom {
    &:after {
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid var(--tooltip-color);
      top: -16px;
      transform: translateX(-50%);
      left: 50%;
      right: auto;
    }

    &:before {
      top: -16px;
      left: 0;
      width: 100%;
      height: 16px;
    }
  }
}
