.adxad-icon-btn {
  display: inline-flex;
  color: $gray300;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #fff;

  &:hover {
    color: $blue500;
  }

  &:not(:disabled):active,
  &:not(:disabled).active {
    color: $blue500;
    background: $borderColor;
  }

  &_stroke,
  &_stroke:active {
    border-color: $borderColor;
  }

  &:disabled {
    color: $disableText;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &.--success:hover {
    color: $green500;
  }

  &.--error:hover {
    color: $red500;
  }

  &.--info:hover {
    color: $blue500;
  }

  &.--warning:hover {
    color: $yellow500;
  }
}
